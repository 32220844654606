import React from 'react';
import {
	Button,
	Checkbox,
	Form,
	Icon,
	Input,
	message,
	Dropdown,
	DatePicker,
	Menu,
	Upload,
	Select,
	Col,
	Layout,
	Row
} from 'antd';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { auth, database } from '../firebase/firebase';
import HorizontalDefault from "./Topbar/HorizontalDefault/index";
import Moment from "moment";
import {version} from "util/config";

import { connect } from 'react-redux';
import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignUp,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';


const FormItem = Form.Item;
const Option = Select.Option;

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file) {
	const isJPG = file.type === 'image/jpeg';
	if (!isJPG) {
		message.error('You can only upload JPG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJPG && isLt2M;
}

class SignUp extends React.Component {
	handleChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ loading: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (imageUrl) =>
				this.setState({
					imageUrl,
					loading: false
				})
			);
		}
	};
	handleSexClick(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ sex: e });
	}
	handleStateClick(e) {
		this.setState({ state: e });
	}

	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to="/profile" />;
		}
	};

	handleSignIn = (err, values) => {
		//console.log('data');
		//console.log(err);
		//console.log(values);
		var codes = [
			"MG0001",
"MG0002",
"MG0003",
"MG0004",
"MG0005",
"MG0006",
"MG0007",
"MG0008",
"MG0009",
"MG0010",
"MG0011",
"MG0012",
"MG0013",
"MG0014",
"MG0015",
"MG0016",
"MG0017",
"MG0018",
"MG0019",
"MG0020",
"MG0021",
"MG0022",
"MG0023",
"MG0024",
"MG0025",
"MG0026",
"MG0027",
"MG0028",
"MG0029",
"MG0030",
"MG0031",
"MG0032",
"MG0033",
"MG0034",
"MG0035",
"MG0036",
"MG0037",
"MG0038",
"MG0039",
"MG0040",
"MG0041",
"MG0042",
"MG0043",
"MG0044",
"MG0045",
"MG0046",
"MG0047",
"MG0048",
"MG0049",
"MG0050",
"MG0051",
"MG0052",
"MG0053",
"MG0054",
"MG0055",
"MG0056",
"MG0057",
"MG0058",
"MG0059",
"MG0060",
"MG0061",
"MG0062",
"MG0063",
"MG0064",
"MG0065",
"MG0066",
"MG0067",
"MG0068",
"MG0069",
"MG0070",
"MG0071",
"MG0072",
"MG0073",
"MG0074",
"MG0075",
"MG0076",
"MG0077",
"MG0078",
"MG0079",
"MG0080",
"MG0081",
"MG0082",
"MG0083",
"MG0084",
"MG0085",
"MG0086",
"MG0087",
"MG0088",
"MG0089",
"MG0090",
"MG0091",
"MG0092",
"MG0093",
"MG0094",
"MG0095",
"MG0096",
"MG0097",
"MG0098",
"MG0099",
"MG0100",
"MG0101",
"MG0102",
"MG0103",
"MG0104",
"MG0105",
"MG0106",
"MG0107",
"MG0108",
"MG0109",
"MG0110",
"MG0111",
"MG0112",
"MG0113",
"MG0114",
"MG0115",
"MG0116",
"MG0117",
"MG0118",
"MG0119",
"MG0120",
"MG0121",
"MG0122",
"MG0123",
"MG0124",
"MG0125",
"MG0126",
"MG0127",
"MG0128",
"MG0129",
"MG0130",
"MG0131",
"MG0132",
"MG0133",
"MG0134",
"MG0135",
"MG0136",
"MG0137",
"MG0138",
"MG0139",
"MG0140",
"MG0141",
"MG0142",
"MG0143",
"MG0144",
"MG0145",
"MG0146",
"MG0147",
"MG0148",
"MG0149",
"MG0150",
"G0001",
"G0002",
"G0003",
"G0004",
"G0005",
"G0006",
"G0007",
"G0008",
"G0009",
"G0010",
"G0011",
"G0012",
"G0013",
"G0014",
"G0015",
"G0016",
"G0017",
"G0018",
"G0019",
"G0020",
"G0021",
"G0022",
"G0023",
"G0024",
"G0025",
"G0026",
"G0027",
"G0028",
"G0029",
"G0030",
"G0031",
"G0032",
"G0033",
"G0034",
"G0035",
"G0036",
"G0037",
"G0038",
"G0039",
"G0040",
"G0041",
"G0042",
"G0043",
"G0044",
"G0045",
"G0046",
"G0047",
"G0048",
"G0049",
"G0050",
"G0051",
"G0052",
"G0053",
"G0054",
"G0055",
"G0056",
"G0057",
"G0058",
"G0059",
"G0060",
"G0061",
"G0062",
"G0063",
"G0064",
"G0065",
"G0066",
"G0067",
"G0068",
"G0069",
"G0070",
"G0071",
"G0072",
"G0073",
"G0074",
"G0075",
"G0076",
"G0077",
"G0078",
"G0079",
"G0080",
"G0081",
"G0082",
"G0083",
"G0084",
"G0085",
"G0086",
"G0087",
"G0088",
"G0089",
"G0090",
"G0091",
"G0092",
"G0093",
"G0094",
"G0095",
"G0096",
"G0097",
"G0098",
"G0099",
"G0100",
"G0101",
"G0102",
"G0103",
"G0104",
"G0105",
"G0106",
"G0107",
"G0108",
"G0109",
"G0110",
"G0111",
"G0112",
"G0113",
"G0114",
"G0115",
"G0116",
"G0117",
"G0118",
"G0119",
"G0120",
"G0121",
"G0122",
"G0123",
"G0124",
"G0125",
"G0126",
"G0127",
"G0128",
"G0129",
"G0130",
"G0131",
"G0132",
"G0133",
"G0134",
"G0135",
"G0136",
"G0137",
"G0138",
"G0139",
"G0140",
"G0141",
"G0142",
"G0143",
"G0144",
"G0145",
"G0146",
"G0147",
"G0148",
"G0149",
"G0150",
"IS001",
"IS002",
"IS003",
"IS004",
"IS005",
"IS006",
"IS007",
"IS008",
"IS009",
"IS010",
"IS011",
"IS012",
"IS013",
"IS014",
"IS015",
"IS016",
"IS017",
"IS018",
"IS019",
"IS020",
"IS021",
"IS022",
"IS023",
"IS024",
"IS025"
		]
		if (!err) {
			var _this = this;
		
		
			//console.log('CALLING IF');
			if (values.avatar) {
				values.avatar = 'https://firebasestorage.googleapis.com/v0/b/curso-demencia.appspot.com/o/avatar-doctor.jpg?alt=media';
			}
			if(values.subspeciality === undefined){
				values.subspeciality = ""
			}
			if(codes.indexOf(values.code) != -1){
			//if(values.age)
			this.setState({ sex: values.sex, state: values.state });
			//console.log('CALLING LOADER');
			//this.props.showAuthLoader();
			
			this.setState({ values: values });
			var age = values.age
			values.age = age.format("DD/MM/YYYY")
			values.email = values.email.toLowerCase()
			console.log(values);
			database.collection('users').where('email', '==', values.email).get().then(function(snapshot) {
				if (snapshot.docs.length == 0) {
					//console.log(Moment().format("DD/MM/YYYY'T'hh:mm:ss"))
					if(values.password.length >= 6){
					values.registration_date = Moment().format("DD/MM/YYYY'T'hh:mm:ss")
					database
						.collection('users')
						.add(values)
						.then(function(docRef) {
							console.log('WRITING USER DATA DONE');
							_this.props.userSignUp(values);
						})
						.catch(function(error) {
							//console.error('Error adding document: ', error);
						});
				} else {
					console.log('Short Password');
					message.error('La contraseña debe de tener al menos 6 caracteres');

					//_this.props.alertMessage = 'Este usuario ya esta registrado';
				}
			}else{
				message.error('Este usuario ya esta registrado');
			
			}
			});
		}else{
			message.error('Código de registro incorrecto');
		}
		} else {
			//console.log('ERROR IS NOT NULL');
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			this.handleSignIn(err, values);
			////console.log(moment(values.birthdate).format('MM/DD/YYYY'));
		});
	};

	constructor() {
		super();
		this.state = {
			loading: false,
			sex: 'Masculino',
			state: 'Aguascalientes',
			redirect: false,
			signing: false,
			values: {}
			//email: 'demo@example.com',
			//password: 'demo#123'
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setRedirect = this.setRedirect.bind(this);
		this.handleSignIn = this.handleSignIn.bind(this);

		this.handleSexClick = this.handleSexClick.bind(this);
		this.handleStateClick = this.handleStateClick.bind(this);
	}

	componentDidMount() {
		this.setState({ sex: 'Masculino', state: 'Aguascalientes' });
	}

	componentDidUpdate() {
		//console.log("V1.233")
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}

		//console.log(this.props);
		//console.log(this.state);
		if (!this.state.signing) {
			if (this.props.authUser !== null) {
				auth.onAuthStateChanged((user) => {
					if (user) {
						//console.log('AUTH USER IS ALL RIGHT');
						//this.props.history.push('/');
						//console.log('redirecting...');
						this.setRedirect();
					} else {
						if (this.state.values.email && this.state.values.password) {
							var _this = this;
							this.setState({ signing: true });
							//console.log(this.props.authUser);
							//console.log('AUTH USER IS NOT NUL BUT FIREBASE IS; SIGNING IN....');
							auth
								.signInWithEmailAndPassword(this.state.values.email, this.state.values.password)
								.then((authUser) => {
									this.props.history.push('//');
								})
								.catch((error) => error);
						} else {
							//console.log('EMPTY VALUES');
						}
					}
				});
			} else {
				//console.log('AUTH USER IS NULL');
			}
		}
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;

		const imageUrl = this.state.imageUrl;
		return (
			<div className="gx-app-login-wrap" >
			<HorizontalDefault/>
			<div className="gx-app-login-wrap" id="login-cont">

				<div className="gx-app-login-container">
			
					<div className="gx-app-login-main-content">
						
						{this.renderRedirect()}

						<div className="gx-app-login-content">
							<Row>
								<Col span="8" />
								<Col span="8">
									{' '}
									<img alt="example" src={require('assets/images/asset_3.png')} />
								</Col>
								<Col span="8" />
							</Row>
							<br />
							<Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
								<Row>
									<Col xl={12} lg={12} md={12} sm={12} xs={12}>
										<FormItem>
											{getFieldDecorator('name', {
												rules: [ { required: true, message: 'Por favor escribe tu nombre' } ]
											})(<Input placeholder="Nombre" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('paternal_lastname', {
												rules: [ { required: true, message: 'Ingrese su apellido paterno' } ]
											})(<Input placeholder="Apellido Paterno" />)}
										</FormItem>

										<FormItem>
											{getFieldDecorator('maternal_lastname', {
												rules: [ { required: true, message: 'Ingrese su apellido materno' } ]
											})(<Input placeholder="Apellido Materno" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('speciality', {
												rules: [ { required: true, message: 'Ingrese su especialidad' } ]
											})(<Input placeholder="Especialidad" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('subspeciality', {
												rules: [ { required: false, message: 'Ingrese su subespecialidad' } ]
											})(<Input placeholder="Sub-especialidad" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('license_number', {
												rules: [ { required: true, message: 'Ingrese su cédula profesional' } ]
											})(<Input placeholder="Cédula profesional" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('insitution', {
												rules: [ { required: true, message: 'Por favor seleccione su institución' } ]
											})(
												<Select
													name="insitution"
													onChange={this.handleSexClick}
													value={this.state.sex}
													placeholder="Institución"
												>
													<Option value="Publica">Pública</Option>
													<Option value="Privada">Privada</Option>
												</Select>
											)}
										</FormItem>

										<FormItem>
											{getFieldDecorator('age', {
												rules: [
													{
														required: true,
														message: 'Por favor seleccione su fecha de nacimiento'
													}
												]
											})(
												<DatePicker
													className="gx-mb-3 gx-w-100"
													showTime={false}
													showToday={false}
													format="DD/MM/YYYY"
													placeholder="Fecha de nacimiento"
								 				/>
											)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('sex', {
												rules: [ { required: true, message: 'Por favor seleccione su sexo' } ]
											})(
												<Select
													name="sex"
													onChange={this.handleSexClick}
													value={this.state.sex}
													placeholder="Sexo"
												>
													<Option value="Masculino">Masculino</Option>
													<Option value="Femenino">Femenino</Option>
												</Select>
											)}
										</FormItem>


										<FormItem>
											{getFieldDecorator('country', {
												rules: [ { required: true, message: 'Por favor ingresa su país' } ]
											})(<Input placeholder="País" />)}
										</FormItem>
									
									</Col>

									<Col xl={12} lg={12} md={12} sm={12} xs={12}>
									<FormItem>
											{getFieldDecorator('state', {
												rules: [ { required: true, message: 'Por favor ingresa su estado' } ]
											})(<Input placeholder="Estado" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('city', {
												rules: [ { required: true, message: 'Por favor ingresa su ciudad' } ]
											})(<Input placeholder="Ciudad" />)}
										</FormItem>
									<FormItem>
											{getFieldDecorator('street', {
												rules: [ { required: true, message: 'Por favor ingresa su calle' } ]
											})(<Input placeholder="Calle" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('interior_num', {
												rules: [ { required: true, message: 'Por favor ingresa su número interior' } ]
											})(<Input placeholder="Número interior" />)}
										</FormItem>
									
										<FormItem>
											{getFieldDecorator('exterior_num', {
												rules: [ { required: false, message: 'Ingrese su código de número exterior' } ]
											})(<Input placeholder="Número exterior" />)}
										</FormItem>
										
										<FormItem>
											{getFieldDecorator('cellphone', {
												rules: [ { required: false, message: 'Ingrese su código de número celular' } ]
											})(<Input placeholder="Teléfono celular" />)}
										</FormItem>
										
										<FormItem>
											{getFieldDecorator('work_phone', {
												rules: [ { required: false, message: 'Ingrese su código de teléfono de consultorio' } ]
											})(<Input placeholder="Teléfono de consultorio" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('code', {
												rules: [ { required: true, message: 'Ingrese su código de acceso' } ]
											})(<Input placeholder="Código de acceso" />)}
										</FormItem>
										{/* <span>Fotografía de perfil</span> */}

										{/* <FormItem>
									{getFieldDecorator('avatar', {
										rules: [
											{
												required: false,
												message: 'Please upload your avatar!'
											}
										]
									})(
										<Upload
											name="avatar"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											//action="//jsonplaceholder.typicode.com/posts/"
											beforeUpload={this.beforeUpload}
											onChange={this.handleChange}
										>
											{imageUrl ? <img src={imageUrl} alt="avatar" /> : uploadButton}
										</Upload>
									)}
								</FormItem> */}
										<FormItem>
											{getFieldDecorator('email', {
												rules: [
													{
														required: true,
														type: 'email',
														message: 'The input is not valid E-mail!'
													}
												]
											})(<Input placeholder="Email" />)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('password', {
												rules: [ { required: true, message: 'Please input your Password!' } ]
											})(<Input type="password" placeholder="Password" />)}
										</FormItem>
									</Col>
								</Row>
								<FormItem>
									{getFieldDecorator('remember', {
										valuePropName: 'checked',
										initialValue: true
									})(
										<Checkbox>
											<IntlMessages id="appModule.iAccept" />
										</Checkbox>
									)}
									<span className="gx-link gx-signup-form-forgot">
									<a className="gx-login-form-forgot" href="https://www.gineco-sr.com/aviso" target="_blank">	<IntlMessages id="appModule.termAndCondition" /></a>

									
									</span>
								</FormItem>
								<FormItem>
									<Button
										type="primary"
										className="gx-mb-0"
										htmlType="submit"
										onClick={this.handleSubmit}
									>
										<IntlMessages id="app.userAuth.signUp" />
									</Button>
									<span>
										<IntlMessages id="app.userAuth.or" />
									</span>{' '}
									<Link to="/signin">
										<IntlMessages id="app.userAuth.signIn" />
									</Link>
								</FormItem>
							</Form>
						</div>

						{loader && (
							<div className="gx-loader-view">
								<CircularProgress />
							</div>
						)}
						{showMessage && message.error(alertMessage)}
					</div>
					<span id="version-code">
				{version}</span>
				</div>
				</div>
			</div>
		);
	}
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignUp,
	hideMessage,
	userSignIn,
	showAuthLoader,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedSignUpForm);
